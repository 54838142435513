<template>
  <div class="addressInfo">
    <div class="userAddressContainer">
      <div class="header flexAndCenter">
        <div>{{ $fanyi("进口商") }}</div>
        <el-select v-model="value2"
                   :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
                   style="margin-right: 0" @change="importerAddressUpdate">
          <el-option
              v-for="item in addressList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-form
          class="firStepForm"
          :model="importerAddressFrom"
          :rules="userAddressRules"
          ref="importerAddressRef"
          label-position="right"
          label-width="156px"
          style="margin-top: 25px"
      >
        <el-form-item prop="name" :label="$fanyi('氏名')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.name"/>
        </el-form-item>
        <el-form-item :label="$fanyi('ローマ字')+'：'" prop="name_rome">
          <el-input
              v-model="importerAddressFrom.name_rome"
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"/>
        </el-form-item>
        <el-form-item prop="zip" :label="$fanyi('邮编')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              :maxlength="8"
              @input="importerAddressZipChannelInputLimit" v-model="importerAddressFrom.zip"/>
        </el-form-item>
        <el-form-item prop="mobile" :label="$fanyi('手机')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.mobile"/>
        </el-form-item>
        <el-form-item prop="address" :label="$fanyi('住所')+'：'">
          <el-input
              resize="none"
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.address"
              type="textarea"/>
        </el-form-item>
        <el-form-item :label="$fanyi('ローマ字')+'：'">
          <el-input
              resize="none"
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.address_rome"
              type="textarea"/>
        </el-form-item>
        <el-form-item :label="$fanyi('法人番号')+'：'" prop="corporate_name">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.corporate_name"/>
        </el-form-item>
        <el-form-item :label="$fanyi('リアルタイム口座(14桁)')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.account"/>
        </el-form-item>
        <el-form-item :label="$fanyi('輸出入者コード')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.standard_code" @input="$forceUpdate()"/>
        </el-form-item>
        <el-form-item :label="$fanyi('公司名')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              :maxlength="255"
              v-model="importerAddressFrom.company"/>
        </el-form-item>
        <el-form-item :label="$fanyi('座机')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="importerAddressFrom.tel"/>
        </el-form-item>
      </el-form>
    </div>
    <div class="userAddressContainer" style="margin-left: 60px">
      <div class="header flexAndCenter">
        <div>{{ $fanyi("收货地址") }}</div>
        <el-select v-model="value1"
                   :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
                   style="margin-right: 0" @change="userAddressUpdate">
          <el-option
              v-for="(item,index) in newAddressList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <el-form
          class="firStepForm"
          :model="userAddressFrom"
          :rules="userAddressRules"
          ref="userAddressRef"
          label-position="right"
          label-width="138px"
          style="margin-top: 25px"
      >
        <el-form-item prop="name" :label="$fanyi('氏名')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="userAddressFrom.name"/>
        </el-form-item>
        <el-form-item :label="$fanyi('ローマ字')+'：'">
          <div class="formInput">
            <el-input
                :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
                v-model="userAddressFrom.name_rome"/>
          </div>
        </el-form-item>
        <el-form-item prop="zip" :label="$fanyi('邮编')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="userAddressFrom.zip" :maxlength="8"
              @input="userAddressZipChannelInputLimit"/>
        </el-form-item>
        <el-form-item prop="mobile" :label="$fanyi('手机')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="userAddressFrom.mobile"/>
        </el-form-item>
        <el-form-item prop="address" :label="$fanyi('住所')+'：'">
          <el-input
              resize="none"
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="userAddressFrom.address"
              type="textarea"/>
        </el-form-item>
        <el-form-item :label="$fanyi('ローマ字')+'：'">
          <el-input
              resize="none"
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="userAddressFrom.address_rome"
              type="textarea"/>
        </el-form-item>
        <!--        <el-form-item :label="$fanyi('法人番号')+'：'">-->
        <!--          <el-input :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"-->
        <!--                    v-model="userAddressFrom.corporate_name"/>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item :label="$fanyi('リアルタイム口座')+'：'">-->
        <!--          <el-input :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"-->
        <!--                    v-model="userAddressFrom.account"/>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item :label="$fanyi('標準コード')+'：'">-->
        <!--          <el-input :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"-->
        <!--                    v-model="userAddressFrom.standard_code"/>-->
        <!--        </el-form-item>-->
        <el-form-item :label="$fanyi('公司名')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              :maxlength="255"
              v-model="userAddressFrom.company"/>
        </el-form-item>
        <el-form-item :label="$fanyi('座机')+'：'">
          <el-input
              :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
              v-model="userAddressFrom.tel"/>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-left: 60px" class="userAddressContainer">
      <div class="title">{{ $fanyi("国際運送会社選択") }}</div>
      <div class="elSelectContainer">
        <img v-show="isRwLogistics==true"
             src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/client_level_icon/svip.png"
             style="width: 30px;height: 30px;margin-right: 10px">
        <el-select v-model="selectValue" size="small" @change="getLogisticsTransportationTips"
                   :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)">
          <el-option
              v-for="item in option"
              :key="item.id"
              :label="item.name"
              :disabled="item.usable==false"
              @click.native="triggerChange(item)"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="selectLogisticsCompanyExplain">{{ logisticsTransportationTips }}</div>
      <div v-if="logisticsTransportationTips===''" style="margin-top: -75px">
        <div v-show="isRwLogistics==true" class="colorB4272B fontSize14" style="margin-bottom: 20px">
          定額会員限定ルートで、12日間のタイムリミットを設けます。
        </div>
        <div class="colorB4272B fontSize14" style="margin-bottom: 10px">
          注意：国際荷物の通関時に発生した関税はお客様のご負担となります。
        </div>
        <div class="colorB4272B fontSize14" style="margin-bottom: 10px">
          EMSを除く配送方法は運送会社から税金を立て替えてからお客様に請求されます。(立替手数料が発生する可能性がございます)
        </div>
      </div>
      <div class="title">{{ $fanyi("特殊要求备注") }}：</div>
      <div class="textarea">
        <el-input
            type="textarea"
            resize="none"
            :disabled="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)"
            v-model="textarea2">
        </el-input>
      </div>
      <div class="title margin-top-49">{{ $fanyi("配送单附件") }}</div>
      <upload
          class="upload-demo"
          :action="uploadUrl"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-exceed="handleExceed"
          :http-request="httpup"
          :before-upload="beforeAvatarUpload"
          :limit="3"
          :file-list="fileList"
          v-show="$route.query.type==='temporary'||(deliveryDetails.is_y_editing===0&&(deliveryDetails.porder_status==20||deliveryDetails.porder_status==10))">
        <div class="flexAndCenter">
          <img src="../../../../../../../assets/5.png" alt="" style="width:16px;margin-right:7px">
          <el-button size="small" type="text" style="color: #50C136;text-decoration: underline;font-size:14px">
            {{ $fanyi("添加附件") }}
          </el-button>
        </div>
      </upload>
      <div class="fontSize12 colorB4272B" style="margin-top: 12px"
           v-show="$route.query.type==='temporary'||(deliveryDetails.is_y_editing===0&&(deliveryDetails.porder_status==20||deliveryDetails.porder_status==10))">
        {{ $fanyi("*单个文件不能超过1GB，一个订单最多上传3个文件。") }}
      </div>
      <div v-for="(item,index) in fileList" :key="index" class="fileListContainer flexAndCenter"
           @click="handlePreview(item)"
           v-show="$route.query.type!=='temporary'&&((deliveryDetails.is_y_editing===0&&deliveryDetails.porder_status>20)||deliveryDetails.is_y_editing===1)">
        <img src="../../../../../../../assets/6.png" alt="">
        <el-popover trigger="hover">
          <div class="fileName fontSize14 color333">{{ item.name }}</div>
          <div style="max-width: 84px;" class="fileName fontSize14 color333" slot="reference">{{ item.name }}</div>
        </el-popover>
      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="dialogVisible"
        append-to-body
        center
        title="注意"
        width="30%">
      <span>
        お客様は定額会員ではない為、定額会員限定ルート「{{ logistics_name }}」のご利用はできません。
        ご希望の場合は、定額会員を申し込んで頂くようにお願い致します。
      </span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">閉じる</el-button>
        <el-button v-if="logistics_name!='Raku-DQ'" type="primary" @click="pageJump(1)">RW船便説明ページへ</el-button>
    <el-button type="primary" @click="pageJump(2)">今すぐ申し込む</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import upload from "@/components/upload";
import {mapState} from "vuex";

export default {
  name: "addressInfo",
  components: {
    upload
  },
  props: {
    porder_status: {//订单状态
      type: String,
      default: '',
    },
    deliveryDetails: {//订单数据
      type: Object,
      default: {},
    },
    timer: {//刷新子组件
      type: String
    }
  },
  data() {
    return {
      value1: '',
      value2: '',
      dialogVisible: false,
      userAddressFrom: {
        name: '',
        company: '',
        address: '',
        zip: '',
        mobile: '',
        tel: '',
        name_rome: '',
        address_rome: '',
        corporate_name: '',
        account: '',
        standard_code: '',
      },
      addressList: [],
      newAddressList: [],
      importerAddressFrom: {
        name: '',
        company: '',
        address: '',
        zip: '',
        mobile: '',
        tel: '',
        name_rome: '',
        address_rome: '',
        corporate_name: '',
        account: '',
      },
      selectValue: '',
      textarea2: '',
      isRwLogistics: false,
      logisticsTransportationTips: '',
      fileList: [],
      logistics_name: '',
      fileName: '',
      userAddressRules: {
        name: [
          {
            required: true,
            message: this.$fanyi("请输入氏名"),
            trigger: "blur",
          },
          {
            min: 1,
            max: 99,
            message: this.$fanyi("长度不能超出99个字符"),
          }
        ],
        name_rome: [{
          required: true,
          message: 'ローマ字を入力してください',
          trigger: ["blur", "change"],
        }],
        address: [{
          required: true,
          message: this.$fanyi("请输入住所"),
          trigger: ["blur", "change"],
        }],
        receiverTitle: '',
        importerTitle: '',
        zip: [{
          required: true,
          message: this.$fanyi("请输入邮编"),
          trigger: ["blur", "change"],
        },
          {
            min: 7,
            max: 8,
            message: this.$fanyi("邮编格式不正确"),
          }],
        mobile: [
          {
            required: true,
            message: this.$fanyi("手机号为空"),
            trigger: "change",
          },
        ],
        account: [
          {
            pattern: /^(\w){14}$/,
            message: this.$fanyi("格式不正确"),
          },
        ],
        corporate_name: [
          {
            pattern: /^[A-Za-z0-9]+$/,
            message: '13桁の数字で記入してください',
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      option: "logisticsTransportation",
    }) /* 推荐 */,
    client_remark: {
      get() {
        return this.$store.state.client_remark;
      },
      set() {
      }
    }
  },
  created() {
    this.getData();
    this.$api.internationalLogisticsTransportation().then((res) => {
      if (res.code != 0) return;
      if (res.data.length == 0) return false;
      res.data.forEach((item) => {
        if (item.name == 'RW船便' || item.name == 'Raku-DQ') {
          item.name += '―定額会員限定ルート'
        }
      })
      this.wuLiuOptions = res.data;
      this.$store.commit("getLogisticsTransportation", {logisticsTransportation: res.data});
      if (this.$route.query.type === 'temporary') {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].id == 14) {
            this.selectValue = res.data[i].id;
            this.logisticsTransportationTips = res.data[i].tips;
            break;
          }
        }
        this.importerAddressFrom.corporate_name = this.deliveryDetails.user_detail.tax_no;
        this.importerAddressFrom.account = this.deliveryDetails.user_detail.bank_no;
        this.importerAddressFrom.standard_code = this.deliveryDetails.user_detail.standard_no;
      } else {
        for (let i = 0; i < res.data.length; i++) {
          if (this.deliveryDetails.logistics_group.length > 0) {
            if (res.data[i].id === this.deliveryDetails.logistics_group[0].logistics_id) {
              this.selectValue = this.deliveryDetails.logistics_group[0].logistics_id;
              this.logisticsTransportationTips = res.data[i].tips
              if (this.deliveryDetails.logistics_group[0].logistics_name == 'RW船便' || this.deliveryDetails.logistics_group[0].logistics_name == 'Raku-DQ') {
                this.isRwLogistics = true;
              }
              break;
            } else {
              this.selectValue = this.deliveryDetails.logistics_group[0].logistics_id;
            }
          } else {
            if (res.data[i].id === this.deliveryDetails.porder_logistics_id) {
              this.selectValue = this.deliveryDetails.porder_logistics_id;
              this.logisticsTransportationTips = res.data[i].tips;
              if (this.deliveryDetails.porder_logistics_name == 'RW船便' || this.deliveryDetails.porder_logistics_name == 'Raku-DQ') {
                this.isRwLogistics = true;
              }
              break;
            } else {
              this.selectValue = this.deliveryDetails.logistics_id;
            }
          }
        }
        this.fileList = this.deliveryDetails.porder_file;
        this.textarea2 = this.deliveryDetails.porder_client_remark;
        this.userAddressFrom = {
          name: this.deliveryDetails.address.receiver.contacts,
          company: this.deliveryDetails.address.receiver.company,
          address: this.deliveryDetails.address.receiver.address,
          zip: this.deliveryDetails.address.receiver.zip_code,
          mobile: this.deliveryDetails.address.receiver.mobile,
          tel: this.deliveryDetails.address.receiver.tel,
          name_rome: this.deliveryDetails.address.receiver.contacts_roma,
          address_rome: this.deliveryDetails.address.receiver.address_roma,
          corporate_name: this.deliveryDetails.address.receiver.tax_no,
          account: this.deliveryDetails.address.receiver.bank_no,
          standard_code: this.deliveryDetails.address.receiver.standard_no,
        }
        this.importerAddressFrom = {
          name: this.deliveryDetails.address.importer.contacts,
          company: this.deliveryDetails.address.importer.company,
          address: this.deliveryDetails.address.importer.address,
          zip: this.deliveryDetails.address.importer.zip_code,
          mobile: this.deliveryDetails.address.importer.mobile,
          tel: this.deliveryDetails.address.importer.tel,
          name_rome: this.deliveryDetails.address.importer.contacts_roma,
          address_rome: this.deliveryDetails.address.importer.address_roma,
          corporate_name: this.deliveryDetails.address.importer.tax_no,
          account: this.deliveryDetails.address.importer.bank_no,
          standard_code: this.deliveryDetails.address.importer.standard_no,
        }
        this.receiverTitle = this.deliveryDetails.address.receiver.title;
        this.importerTitle = this.deliveryDetails.address.importer.title;
      }
    });
  },
  methods: {
    //获取物流tips
    getLogisticsTransportationTips(e) {
      this.option.forEach((item) => {
        if (item.id === e) {
          this.logisticsTransportationTips = item.tips;
          this.isRwLogistics = item.name == 'RW船便―定額会員限定ルート' || item.name == 'Raku-DQ―定額会員限定ルート';
        }
      })
    },
    triggerChange(item) {
      switch (item.name) {
        case 'RW船便―定額会員限定ルート':
          this.logistics_name = 'RW船便';
          break;
        case 'Raku-DQ―定額会員限定ルート':
          this.logistics_name = 'Raku-DQ';
          break;
      }
      if (!item.usable) {
        this.dialogVisible = true;
      }
    },
    pageJump(val) {
      if (val == 1) {
        window.open('https://blog.rakumart.com/detail?id=73')
      } else {
        window.open('/tollVipApplyFor')
      }
    },
    //获取物流列表
    getData() {
      this.$api.userAddressList({
        page: 1,
        pageSize: 9999
      }).then((res) => {
        this.addressList = res.data.data;
        this.newAddressList = this.$fun.deepClone(res.data.data);
        this.newAddressList.push({
          id: 999,
          name: 'Amazon.co.jp FBA入庫係',
          company: '',
          address: '日本',
          zip: '000-0000',
          mobile: '000-0000-0000',
          tel: '',
          name_rome: '',
          address_rome: '',
          corporate_name: '',
          account: '',
          standard_code: '',
          title: '日本倉庫仮配送先'
        })
        for (let i = 0; i < res.data.data.length; i++) {
          if (this.$route.query.type == undefined) {
            if (this.deliveryDetails.address.receiver.title === '日本倉庫仮配送先') {
              this.value1 = 999;
            } else if (this.deliveryDetails.address.receiver.title === res.data.data[i].title) {
              this.value1 = res.data.data[i].id;
              this.$forceUpdate();
            }
            if (this.deliveryDetails.address.importer.title === res.data.data[i].title) {
              this.value2 = res.data.data[i].id
              this.$forceUpdate();
            }
          }
        }
      })
    },
    //更新用户输入的备注
    updateTextarea(e) {
      this.$store.commit("updateClient_remark", {text: e});
    },
    //文件删除
    handleRemove(val) {
      const index = this.fileList.findIndex(item => item.url === val.url)
      this.fileList.splice(index, 1)
    },
    //文件url转换成可下载的文件流
    handlePreview(file) {
      window.open(file.file)
    },
    //用户收货地址更新
    userAddressUpdate(e) {
      for (let i = 0; i < this.newAddressList.length; i++) {
        if (this.newAddressList[i].id === e) {
          this.receiverTitle = this.newAddressList[i].title;
          this.userAddressFrom = {
            name: this.newAddressList[i].name,
            company: this.newAddressList[i].company,
            address: this.newAddressList[i].address,
            zip: this.newAddressList[i].zip,
            mobile: this.newAddressList[i].mobile,
            tel: this.newAddressList[i].tel,
            name_rome: this.newAddressList[i].name_rome,
            address_rome: this.newAddressList[i].address_rome,
            corporate_name: this.newAddressList[i].corporate_name,
            account: this.newAddressList[i].account,
            standard_code: this.newAddressList[i].standard_code,
            title: this.newAddressList[i].title
          }
          this.$refs.userAddressRef.validate((valid) => {
            if (valid) {

            }
          });
          break;
        }
      }
    },
    //进口商收货地址更新
    importerAddressUpdate(e) {
      for (let i = 0; i < this.addressList.length; i++) {
        if (this.addressList[i].id === e) {
          this.importerTitle = this.addressList[i].title;
          this.importerAddressFrom = {
            name: this.addressList[i].name,
            company: this.addressList[i].company,
            address: this.addressList[i].address,
            zip: this.addressList[i].zip,
            mobile: this.addressList[i].mobile,
            tel: this.addressList[i].tel,
            name_rome: this.addressList[i].name_rome,
            address_rome: this.addressList[i].address_rome,
            corporate_name: this.deliveryDetails.user_detail == undefined || this.deliveryDetails.user_detail.tax_no == '' ? this.addressList[i].corporate_name : this.deliveryDetails.user_detail.tax_no,
            account: this.deliveryDetails.user_detail == undefined || this.deliveryDetails.user_detail.bank_no == '' ? this.addressList[i].account : this.deliveryDetails.user_detail.bank_no,
            standard_code: this.deliveryDetails.user_detail == undefined || this.deliveryDetails.user_detail.standard_no == '' ? this.addressList[i].standard_code : this.deliveryDetails.user_detail.standard_no,
            title: this.addressList[i].title
          }
          this.$refs.importerAddressRef.validate((valid) => {
            if (valid) {

            }
          });
          break;
        }
      }
    },
    //收货地址邮编输入校验
    userAddressZipChannelInputLimit(e) {
      this.userAddressFrom.zip = e.replace(/[^0-9- ]/g, '');
    },
    //进口商邮编输入校验
    importerAddressZipChannelInputLimit(e) {
      this.importerAddressFrom.zip = e.replace(/[^0-9- ]/g, '');
    },
    // 图片上传事件
    async httpup(file) {
      this.fileName = file.file.name;
      const imgOBJ = await this.$fun.startUpload('multipartUpload', file.file, this.MastermaphandleUploadProgress)
      if (imgOBJ.res.requestUrls[0] && imgOBJ.res.status == 200) {
        this.uploadPercentage = 0
        this.handleSuccess(imgOBJ.res.requestUrls[0])
      }
    },
    MastermaphandleUploadProgress(rawFile, num) {
      // 使用 Number.isFinite 来检查 num 是否是一个有限数字
      if (Number.isFinite(num)) {
        this.uploadPercentage = num;
      } else {
        this.uploadPercentage = 50
      }
    },
    //文件上传成功
    handleSuccess(file) {
      this.loading.close();
      this.fileList.push({name: this.fileName, file: file});
    },
    //文件删除
    beforeRemove(file, fileList) {
      return this.$confirm(`${this.$fanyi("确定移除")} ${file.name}？`);
    },
    //文件大小限制
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 1024;
      if (!isLt5M) {
        this.$message.error('文件大小超出限制');
        return isLt5M
      }
      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi("文件上传中"),
        spinner: "el-icon-loading fontSize50",
        background: "rgba(255, 255, 255, 0.7)",
      });
    },
    //文件数量限制
    handleExceed(files, fileList) {
      this.$message.warning(`${this.$fanyi("当前限制选择 3 个文件")}`);
    },
  }
}
</script>

<style lang="scss" scoped>
.addressInfo {
  border-radius: 10px;
  background: #FFF8EE;
  padding: 30px 0 0 25px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  z-index: 6;

  .margin-left-41 {
    margin-left: 41px;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  .margin-top-49 {
    margin-top: 49px;
  }

  .header {
    div {
      color: #333333;
      font-weight: bold;
      font-size: 18px;
      margin-right: 20px;
    }

    /deep/ .el-input__inner {
      width: 200px !important;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }

    /deep/ .el-input__icon {
      line-height: 30px;
    }

    .el-input {
      /deep/ input {
        /deep/ ::placeholder {
          color: #fff !important;
        }

        //color:#fff !important;
        /deep/ ::-webkit-input-placeholder {
          color: #fff !important;
        }
      }
    }
  }

  .title {
    color: #333333;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .fileListContainer {
    margin-bottom: 21px;

    img {
      width: 12px;
      height: 12px
    }

    .fileName {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 3px;
      cursor: pointer;
      text-decoration: underline;
      color: #2133BC;
    }
  }

  .userAddressContainer {
    /deep/ .el-input__inner {
      width: 200px;
      height: 30px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }

    /deep/ .is-disabled {
      .el-input__inner {
        background: #F9F9F9;
        color: #000;
      }
    }


    /deep/ .el-textarea__inner {
      width: 200px;
      height: 64px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 3px;
    }

    /deep/ .is-disabled {
      .el-textarea__inner {
        background: #F9F9F9;
        color: #000;
      }
    }

    .elSelectContainer {
      display: flex;
      align-items: center;

      /deep/ .el-input__inner {
        width: 200px;
        height: 30px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
      }
    }

    /deep/ .is-disabled {
      .el-input__inner {
        background: #F9F9F9;
        color: #000;
      }
    }

    .textarea {
      /deep/ .el-textarea__inner {
        width: 350px;
        height: 153px;
        background: #FFFFFF;
        border: 1px solid #DFDFDF;
        border-radius: 3px;
      }

      /deep/ .is-disabled {
        .el-textarea__inner {
          background: #F9F9F9;
          color: #000;
        }
      }
    }

    .selectLogisticsCompanyExplain {
      width: 205px;
      font-size: 12px;
      color: #B4272B;
      margin-top: 11px;
      height: 69px;
    }

    .el-form-item {
      margin-bottom: 32px;
    }

    /deep/ .el-form-item__label {
      font-size: 12px;
    }


    /deep/ .el-form-item__error {
      color: #B4272B;
      top: 104%
    }

    /deep/ .el-upload-list__item-name {
      width: 280px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    /deep/ .el-progress__text {
      display: none !important;
    }
  }
}
</style>
